
import { Component, Prop } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getConfigEnv, getComponent } from '@/utils/helpers';
import gptModule from '@/store/modules/gptModule';
import { getNameByEmail } from '@/utils/users';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import workflowModule from '@/store/modules/workflowModule';
import padSignalsModule from '@/store/modules/padSignalsModule';

@Component({
  components: {
    TasqItem: () => getComponent('tasqs/TasqItem'),
    TasqMiniView: () => getComponent('tasqs/TasqMiniView'),
  },
})
export default class PadItem extends mixins(GenericMixin) {
  @Prop({ type: String, required: false }) tasqId?: string;

  @Prop({ type: String, required: false }) tasqWellName?: string;

  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  @Prop({ type: Boolean, required: false }) tasqProducing?: string;

  @Prop({ type: Object, required: false }) tasqObject?: TasqJob;

  wellListExpanded = false;

  showAllWells = true;

  MAX_WELL_COUNT = 20;

  showRelatedWells = false;

  defermentUnits = 'bpd';

  get getPadDefermentUnit() {
    if (this.showRelatedWells) {
      let localDefermentUnits = 'bpd';
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.padWells.length; x++) {
        if (
          this.padWells[x].defermentValue !== undefined
          && !this.padWells[x].completed
        ) {
          localDefermentUnits = this.padWells[x].defermentUnit;
          // @ts-ignore
        }
      }
      this.defermentUnits = localDefermentUnits;
      if (
        getConfigEnv('OPERATOR_LOWERCASED') === 'swn'
        && this.defermentUnits === 'bpd'
      ) {
        return '';
      }
      return `${this.defermentUnits} total`;
    }
    return '';
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  }

  getPadName(name) {
    return this.toTitleCase(name.replaceAll('_', ' '));
  }

  allWellListToggle() {
    if (this.showAllWells) {
      return 'Show producing';
    }
    return 'Show producing';
  }

  get isSmallScreen() {
    return window.innerWidth < 1280;
  }

  get predictionTypeWell() {
      return this.tasq.predictionType || 'Producing';
  }

  get wellAlarmsData() {
    return (workflowModule.alarmByWellName(this.getPadName(this.tasq.wellName))) || null;
  }

  get isAlarmActive() {
    return this.wellAlarmsData && this.wellAlarmsData.alarm;
  }

  get isShutdownAlarmActive() {
    return this.wellAlarmsData && this.wellAlarmsData.shutdown;
  }

  get alarmTime() {
    return this.wellAlarmsData && this.wellAlarmsData.time ? this.wellAlarmsData.time : null;
  }

  get alarmType() {
    return this.wellAlarmsData && this.wellAlarmsData.alarm_type
    ? this.wellAlarmsData.alarm_type : null;
  }

  wellExpandedIcon() {
    if (this.wellListExpanded) {
      return 'arrow_drop_down';
    }
    return 'arrow_drop_up';
  }

  wellExpandedText() {
    if (this.wellListExpanded) {
      return 'Hide wells';
    }
    return 'Show wells';
  }

  async showWellsClicked() {
    await this.sleep(500);
    this.wellListExpanded = !this.wellListExpanded;
  }

  get padWells() {
    // @ts-ignore
    return this.tasq.wells;
  }

  colors = {
    workflow: 'purple003',
    'off-target rt': 'red005',
    'off-target': 'orange002',
    'no-comms': 'gray008',
    'no comms': 'gray008',
    producing: 'gray019',
    predictions: 'green015',
    prediction: 'green015',
    failure: 'green015',
    'failure prediction': 'green015',
    anomaly: 'yellow001',
    'state change': 'yellow001',
    setpoint: 'blue005',
  };

  getPredictionTypesColor(key) {
    let barColor = this.colors[key.toLowerCase()];
    if (!barColor) {
      barColor = 'gray018';
    }
    return barColor;
  }

  getPredictionTypesWidth(value) {
    const width = Math.floor((value / this.tasq!.wells.length) * 100);
    return width;
  }

  get predictionTypesLength() {
    return Object.keys(this.predictionTypes).length;
  }

  get predictionTypes() {
    if (!this.showRelatedWells) {
      return {};
    }

    const predictionTypesObject = {};
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < this.tasq!.wells.length; x++) {
      let { predictionType } = this.tasq!.wells[x];
      if (this.tasq!.wells[x].completed) {
        predictionType = 'Producing';
      }
      if (predictionTypesObject[predictionType] !== undefined) {
        predictionTypesObject[predictionType] += 1;
      } else {
        predictionTypesObject[predictionType] = 1;
      }
    }
    return predictionTypesObject;
  }

  get tasq(): any | undefined {
    const currentRouteName: any = this.$route.name;
    if (
      this.tasqObject
      && this.tasqObject.wellName
    ) {
      return this.tasqObject;
    }
    if (this.tasqId === null) {
      return undefined;
    }
    return tasqsListModule.tasqById(this.tasqId!);
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get wellTodoCurrentUserStatus() {
    const wellsAssigneStatus = gptModule.wellsAssigneeStatus;

    const wellStatus = wellsAssigneStatus.find(
      (w) => w.wellName === this.tasq.wellName,
    );

    if (wellStatus) {
      return wellStatus;
    }

    return null;
  }

  get producingTasq(): any | any {
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < this.enabledWells.length; x++) {
      if (
        this.tasqWellName != null
        && this.enabledWells[x].wellName === this.tasqWellName
      ) {
        return this.enabledWells[x];
      }
    }
    return null;
  }

  get isTasqActive(): boolean {
    return this.tasq?.id === this.activeTasq?.id;
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get uniqueWells() {
    return (this.tasq.wells);
  }

  get getWells() {
    if (this.showRelatedWells) {
      return this.uniqueWells.sort((a, b) => b.defermentValue - a.defermentValue);
    }
    return [];
  }

  async created() {
    setTimeout(() => {
      this.showRelatedWells = true;
    }, 1500);
  }

  formatDateString(dateString) {
    // Get the date.
    const date = new Date(dateString);

    // Get the month, day, and year.
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    // Get the hour, minute, and second.
    const hour = date.getHours();
    const minute = date.getMinutes();
    // const second = date.getSeconds();

    // Make the time in 2 digits.
    const paddedHour = hour.toString().padStart(2, '0');
    const paddedMinute = minute.toString().padStart(2, '0');
    // const paddedSecond = second.toString().padStart(2, '0');

    // Format the date time string in US format with the time in 2 digits.
    const dateTimeString = `${month}/${day}/${year} ${paddedHour}:${paddedMinute}`;

    return dateTimeString;
  }

  goToTasqPage(type) {
    padSignalsModule.setProductionController('abort');
    const currentRouteName: any = this.$route.name;
    if (type === 'producing') {
      this.$router.push({
        name: currentRouteName,
        params: {
          id: this.tasq?.wellName || '',
        },
        query: { type: 'producing', view: 'padview' },
      });
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
    } else {
      this.$router.push({
        name: currentRouteName,
        params: {
          id: this.tasq?.id || '',
        },
        query: { type: 'id', view: 'padview' },
      });
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
    }
  }
}
